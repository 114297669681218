import BigNumber from "bignumber.js";
import moment from "moment/moment";

export const func = {
    getStringBalanceMix: (balance) => {
        if(balance == null) {
            return 0
        }

        const wantDecimals = 5
        const decimals = 18

        const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

        let BNbalance

        if(typeof balance === "object") {
            BNbalance = new BN(balance.toString())
        } else {
            BNbalance = new BN(balance)
        }

        // const T = BNbalance.toFormat()
        // const L = BNbalance.div(10 ** decimals).toFormat()
        // console.log(L)
        return BNbalance.div(10 ** decimals).toFormat()
    },
    getBalanceUnformated: (balance) => {
        if(balance == null) {
            return 0
        }

        const wantDecimals = 5
        const decimals = 18

        const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

        let BNbalance

        if(typeof balance === "object") {
            BNbalance = new BN(balance.toString())
        } else {
            BNbalance = new BN(balance)
        }

        const T = BNbalance.div(10 ** decimals).toString(10)
        return T
    },
    getBalanceUnformatedNoDecimals: (balance) => {
        if(balance == null) {
            return 0
        }

        const wantDecimals = 5
        const decimals = 18

        const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

        let BNbalance

        if(typeof balance === "object") {
            BNbalance = new BN(balance.toString())
        } else {
            BNbalance = new BN(balance)
        }

        BN.config({ DECIMAL_PLACES: 0 });

        const T = BNbalance.div(10 ** decimals).toString(10)
        return T
    },
    getDaysFromBigNumber:(BN) => {
        if(BN === null) {
            return
        }
        const T = BN.toString()
        const date = new Date(T * 1000);

        date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7) - 15);
        //console.log(date);

        const days = moment(date).diff(moment(),'days')

        return days
    },
    getDateFromBigNumber:(BN) => {
        if(BN === null) {
            return
        }
        const T = BN.toString()
        const date = new Date(T * 1000);

        date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7) - 15);
        //console.log(date);

        //const day = moment(date).format("MMM Do YYYY");
        const day = moment(date).format("ll");

        return day
        //const days = moment(date).diff(moment(),'days')
        //return days
    },
    getDaysFromBigNumberwithdraw:(BN) => {
        if(BN === null) {
            return
        }
        const T = BN.toString()
        const date = new Date(T * 1000);

        date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7) - 8);
        //console.log(date);

        const days = moment(date).diff(moment(),'days')

        return days
    },
    getDateFromBigNumberwithdraw:(BN) => {
        if(BN === null) {
            return
        }
        const T = BN.toString()
        const date = new Date(T * 1000);

        date.setDate(date.getDate() + (((1 + 7 - date.getDay()) % 7) || 7) - 8);
        //console.log(date);

        //const day = moment(date).format("MMM Do YYYY");
        const day = moment(date).format("ll");

        return day
        //const days = moment(date).diff(moment(),'days')
        //return days
    },

}
