const state = () => ({
    currentMessage: null,
    currentError: null,
    currentProgress: null
})

const getters = {
    getMessage (state) {
        return state.currentMessage
    },
    getError (state) {
        return state.currentError
    },
    getProgress (state) {
        return state.currentProgress
    }
}

const actions = {
    sendMessage (context, msg) {
        context.commit('sendMessage', msg)
    },
    sendError (context, err) {
        context.commit('sendError', err)
    },
    showProgress(context, len) {
        context.commit('showProgress', len)
    }
}

const mutations = {
    sendMessage (state, msg) {
        state.currentMessage = msg
    },
    sendError (state, err) {
        state.currentError = err
    },
    showProgress (state, err) {
        state.currentProgress = err
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}