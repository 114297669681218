import { ethers } from "ethers";
import config from "../config"
import BigNumber from "bignumber.js";
import abi from "@/resources/NSFW.json";
import axios from "axios";

function getStringBalancee(balance) {
    const wantDecimals = 5
    const decimals = 18

    const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

    let BNbalance

    if(typeof balance === "object") {
        BNbalance = new BN(balance.toString())
    } else {
        BNbalance = new BN(balance)
    }

    // const T = BNbalance.toFormat()
    // const L = BNbalance.div(10 ** decimals).toFormat()
    // console.log(L)
    return BNbalance.div(10 ** decimals).toFormat()
}

const state = () => ({
    bsc_usdcBalanceRaw: null,
    bsc_nsfwBalanceRaw: null,
    bsc_maticBalanceRaw: null,
    bsc_nsfwPerUsdc: null
})

// getters
const getters = {
    bsc_usdcBalanceRaw: (state) => {
        return state.bsc_usdcBalanceRaw;
    },
    bsc_usdcBalance: (state) => {
        if(state.bsc_usdcBalanceRaw) {
            return getStringBalancee(state.bsc_usdcBalanceRaw)
        }
        return 0;
    },
    bsc_nsfwBalanceRaw: (state) => {
        return state.bsc_nsfwBalanceRaw;
    },
    bsc_nsfwBalance: (state) => {
        if(state.bsc_nsfwBalanceRaw) {
            return getStringBalancee(state.bsc_nsfwBalanceRaw)
        }
        return 0;
    },

    bsc_maticBalanceRaw: (state) => {
        return state.bsc_maticBalanceRaw;
    },
    bsc_maticBalance: (state) => {
        if(state.bsc_maticBalanceRaw) {
            return getStringBalancee(state.bsc_maticBalanceRaw)
        }
        return 0;
    },
    bsc_nsfwPerUsdc: (state) => {
        if(state.bsc_nsfwPerUsdc) {
            return state.bsc_nsfwPerUsdc
        }
        return 0;
    }
}
// mutations
const mutations = {
    setbsc_USDCBalance(state, payload) {
        state.bsc_usdcBalanceRaw = payload;
    },
    setbsc_NsfwBalance(state, payload) {
        state.bsc_nsfwBalanceRaw = payload;
    },
    setbsc_MaticBalance(state, payload) {
        state.bsc_maticBalanceRaw = payload;
    },
    setbsc_nsfwPerUsdc(state, payload) {
        state.bsc_nsfwPerUsdc = payload;
    }
}

// actions
const actions = {
    async refreshWallet(context) {
        // console.log('refreshWallet called (BSC)')

        const customHttpProvider = new ethers.providers.JsonRpcProvider(config.qnhttpBsc);

        const ethBalance = await customHttpProvider.getBalance(context.getters.wallet);
        context.commit('setbsc_MaticBalance', ethBalance);

        const erc20_nsfw = new ethers.Contract(config.nsfwContractBsc, abi, customHttpProvider);

        erc20_nsfw.balanceOf(context.getters.wallet).then((result) => {
            context.commit('setbsc_NsfwBalance', result);
            // console.log("setbsc_NsfwBalance: " + result);
        });

        axios.get('https://apiv5.paraswap.io/prices?srcToken='+config.nsfwContractBsc+'&srcDecimals=18&destToken='+config.usdcContractBsc+'&destDecimals=18&amount=1000000000000000000&side=SELL&network=56')
            .then(response => (
                    // console.log("USD : " + response.data.priceRoute.srcUSD)
                    context.commit('setbsc_nsfwPerUsdc', response.data.priceRoute.srcUSD)
                )
            );
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}