<template>
  <div id="app">
    <b-navbar toggleable="sm" type="dark" variant="primary">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>


      <b-navbar-nav small>
        <ConnectButton @connectClick="connect" @disconnectClick="disconnect"></ConnectButton>
      </b-navbar-nav>
    </b-navbar>

    <b-alert dismissible show fade variant="danger">Please note that this is an BETA version of the NSFW Staking, which is still under development and needs to be tested before the official release. Functions are partially or completely incomplete. The software and all content is provided on an "as is" and "as available" basis.

      No person shall be liable for any loss, whether direct, indirect, special or consequential, as a result of its use.</b-alert>

    <messageHandler></messageHandler>

    <walletView></walletView>
    <stakeView></stakeView>
    <web3Handler ref="web3Handler"></web3Handler>

    <div style="height: 70px; width: 50px;"></div>

    <b-navbar style="z-index: 900;" toggleable="sm" type="dark" variant="primary" class="bottomNav">

        <b-navbar-nav small>
          <b-button-group>
            <b-nav-item target="_blank" href="https://www.pleasurenet.com/">Info</b-nav-item>
            <b-nav-text> | </b-nav-text>
            <b-nav-item target="_blank" href="https://twitter.com/Pleasure_coin">Twitter</b-nav-item>
            <b-nav-text> | </b-nav-text>
            <b-nav-item target="_blank" href="https://t.me/pleasurecoin_crypto">Telegram</b-nav-item>


          </b-button-group>
        </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item right target="_blank" href="https://www.pleasurecoin.com/">Legal</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import web3Handler from './components/web3Handler'
import ConnectButton from './components/ConnectButton'
import messageHandler from './components/messageHandler'
import walletView from "@/views/walletView.vue";
import stakeView from "@/views/stakeView.vue";

export default {
  name: 'App',
  components: {
    web3Handler,
    ConnectButton,
    messageHandler,
    walletView,
    stakeView
  },
  methods: {
    connect: async function () {
      await this.$refs.web3Handler.connect();
    },
    disconnect: async function () {
      await this.$refs.web3Handler.disconnect();
    }
  },
  mounted: function () {
    //let vm = this;
    this.$nextTick(function () {
      //vm.showMessage()
      //setTimeout(() => { vm.$refs.messageHandler.showMessage("showMessage()"); }, 5000);
    });
  },
  data() {
    return {
      nav: 'wallet'
    }
  }
}
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import 'assets/custom-vars.scss';

// Import Bootstrap and BootstrapVue source SCSS files
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

// General style overrides and custom classes
body {
  margin: 0;
  background: #c700d3;
  background-image: radial-gradient(circle, rgba(199,0,211,1) 0%, rgba(52,6,84,1) 100%);

  //background-image: linear-gradient(146deg, rgba(199,0,211,1) 0%, rgba(52,6,84,1) 100%);

  // background: linear-gradient(0deg, rgba(52,6,84,1) 0%, rgba(199,0,211,1) 100%);
  // background-image: linear-gradient(to right bottom, #c700d3, #9d0db4, #770f94, #530b74, #340654);
  // background-image: linear-gradient(0deg, rgba(52,6,84,1) 0%, rgba(199,0,211,1) 100%);
}

a {
  color: white;
  text-decoration: none;
}

a:link {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
  text-decoration: none;
}



.modal-content {
  background-color: #340654;
}

.card {
  background-color: rgba(199,0,211,0.4);
}

.nav-item-top {
  background-color: #730594;
}

a.nav-item-top.nav-link:hover {
  text-decoration: none;
  color: white !important;
}

.navbar-toggler {
  background-color: #340654;
}

a.nav-link:link {
  text-decoration: none;
  color: white !important;
}

a.nav-link:hover {
  text-decoration: none;
  color: #530b74 !important;
}

a.nav-link:active {
  text-decoration: none;
  color: #340654 !important;
}

.bottomNav {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.dropdown-menu {
  background-color: transparent;
  border: 0;
  top: 70%;
}

.router-link-active {
  color: white !important;
  text-decoration: none !important;
}

.router-link-exact-active {
  color: white !important;
  text-decoration: none !important;
  font-weight: bold;
}

</style>


