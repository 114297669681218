<template>
  <div>
    <div>

    </div>
  </div>
</template>

<script>
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import config from "../config"

// import { ethers } from "ethers";

export default {
  name: "web3Handler",
  data: function () {
    return {
      web3Modal: null,
      web3Provider: {},
      etherS: false,
      timer: null
    }
  },
  methods: {
    connect: async function () {

      const providerOptions = {
        Metamask: {
          display: {
            name: "Metamask",
            description: "Connect with your Browser Wallet"
          },
          network: "any",
          package: null
        },
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "f80d8f135f9842c392dde183aacb4353",
            rpc: {
              137: 'https://polygon-rpc.com',
            },network: "matic",
          }
        }
      };

      this.web3Modal = new Web3Modal({
        //network: "mainnet", // optional
        network: "any", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        disableInjectedProvider: false,
      });

      try {
        this.web3Provider = await this.web3Modal.connect();
      } catch(e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

      console.log("connect provider");

      this.$store.commit('setConnected', true);

      await this.$store.dispatch('setWeb3', this.web3Provider);

      const walletAddress = this.$store.getters.wallet

      this.web3Provider.on(walletAddress, (newBalance) => {
        console.log("newBalance " + newBalance);
        this.$store.dispatch('refreshWallet');
      });

      this.web3Provider.on("accountsChanged", (accounts) => {
        console.log("accountsChanged " + accounts);
        this.$store.dispatch('setWeb3', this.$store.getters.web3).then( () =>
        {
          this.$store.dispatch('refreshWallet');
        })

      });

      // Subscribe to chainId change
      this.web3Provider.on("chainChanged", (chainId) => {
        console.log("chainChanged " + chainId);
        this.$store.dispatch('refreshWallet');
      });

      // Subscribe to session disconnection
      this.web3Provider.on("disconnect", (code, reason) => {
        console.log("disconnect " + code, reason);
        this.$store.commit('setConnected', false);
      });

    },
    disconnect: async function () {
      console.log("disconnect click");
      this.web3Modal.clearCachedProvider();
      this.web3Provider = null;
      // console.log(this.web3Provider);
      // console.log(this.etherS);
      this.$store.commit('setConnected', false);
    }
  },
  mounted: function () {

    if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
      // console.log("Connected")
      this.connect()
/*
      // await this.$store.dispatch('setWeb3', this.web3Provider);
      this.web3Provider =this.$store.getters.web3

      const walletAddress = this.$store.getters.wallet

      this.web3Provider.on(walletAddress, (newBalance) => {
        console.log("newBalance " + newBalance);
        this.$store.dispatch('refreshWallet');
      });

      this.web3Provider.on("accountsChanged", (accounts) => {
        console.log("accountsChanged " + accounts);
        this.$store.dispatch('setWeb3', this.$store.getters.web3);
      });

      // Subscribe to chainId change
      this.web3Provider.on("chainChanged", (chainId) => {
        console.log("chainChanged " + chainId);
      });

      // Subscribe to session disconnection
      this.web3Provider.on("disconnect", (code, reason) => {
        console.log("disconnect " + code, reason);
        this.$store.commit('setConnected', false);
      });
      */
    }

    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.timer = setInterval(() => {
        this.$store.dispatch('refreshWallet');
      }, 60000)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }

}
</script>

<style scoped>

</style>