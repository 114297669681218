import BigNumber from "bignumber.js";

export default {
    data() {
        return {
            msg: 'Hello World',
        }
    },
    created: function () {
        console.log('Printing from the Mixin')
    },
    methods: {
        displayMessage: function () {
            console.log('Now printing from a mixin function')
        },
        getStringBalanceMix: function(balance) {
            const wantDecimals = 5
            const decimals = 18

            const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

            let BNbalance

            if(typeof balance === "object") {
                BNbalance = new BN(balance.toString())
            } else {
                BNbalance = new BN(balance)
            }

            // const T = BNbalance.toFormat()
            // const L = BNbalance.div(10 ** decimals).toFormat()
            // console.log(L)
            return BNbalance.div(10 ** decimals).toFormat()
        },
        getPricePer: function(token, tokenPrice, decimals) {
            const BN = BigNumber.clone({ DECIMAL_PLACES: 2 })

            let BNbalance

            if(typeof token === "object") {
                BNbalance = new BN(token.toString())
            } else {
                BNbalance = new BN(token)
            }

            const val = BNbalance.multipliedBy(tokenPrice)

            return val.div(10 ** decimals).toFormat()
        }
    },
}
