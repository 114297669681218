import { ethers } from "ethers";
import config from "../config"
import abi from "@/resources/NSFW.json";
import axios from "axios";

const BigNumber = require('bignumber.js');
BigNumber.config({ DECIMAL_PLACES: 9 })

function getStringBalancee(balance) {
    /*
    let maticBal = balance.toString();

    if(maticBal.length <= 18) {
        maticBal = String(maticBal).padStart(19, '0');
    }

    const newString = maticBal.slice(0, maticBal.length - 18 )
        + '.'
        + maticBal.slice(maticBal.length - 18, maticBal.length - 18 + 5);

    const num = Number(newString);
    return num.toLocaleString('en-US', {minimumFractionDigits: 5});
    */

    const wantDecimals = 5
    const decimals = 18

    const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

    let BNbalance

    if(typeof balance === "object") {
        BNbalance = new BN(balance.toString())
    } else {
        BNbalance = new BN(balance)
    }

    // const T = BNbalance.toFormat()
    // const L = BNbalance.div(10 ** decimals).toFormat()
    // console.log(L)
    return BNbalance.div(10 ** decimals).toFormat()
}

const state = () => ({
    eth_usdcBalanceRaw: null,
    eth_nsfwBalanceRaw: null,
    eth_maticBalanceRaw: null,
    eth_nsfwPerUsdc: null
})

// getters
const getters = {
    eth_usdcBalanceRaw: (state) => {
        return state.eth_usdcBalanceRaw;
    },
    eth_usdcBalance: (state) => {
        if(state.eth_usdcBalanceRaw) {
            return getStringBalancee(state.eth_usdcBalanceRaw)
        }
        return 0;
    },
    eth_nsfwBalanceRaw: (state) => {
        return state.eth_nsfwBalanceRaw;
    },
    eth_nsfwBalance: (state) => {
        if(state.eth_nsfwBalanceRaw) {
            return getStringBalancee(state.eth_nsfwBalanceRaw)
        }
        return 0;
    },

    eth_maticBalanceRaw: (state) => {
        return state.eth_maticBalanceRaw;
    },
    eth_maticBalance: (state) => {
        if(state.eth_maticBalanceRaw) {
            return getStringBalancee(state.eth_maticBalanceRaw)
        }
        return 0;
    },
    eth_nsfwPerUsdc: (state) => {
        if(state.eth_nsfwPerUsdc) {
            return state.eth_nsfwPerUsdc
        }
        return 0;
    }
}
// mutations
const mutations = {
    seteth_USDCBalance(state, payload) {
        state.eth_usdcBalanceRaw = payload;
    },
    seteth_NsfwBalance(state, payload) {
        state.eth_nsfwBalanceRaw = payload;
    },
    seteth_MaticBalance(state, payload) {
        state.eth_maticBalanceRaw = payload;
    },
    seteth_nsfwPerUsdc(state, payload) {
        state.eth_nsfwPerUsdc = payload;
    }
}

// actions
const actions = {
    async refreshWallet(context) {
        // console.log('refreshWallet called (Etherum)')

        const customHttpProvider = new ethers.providers.JsonRpcProvider(config.qnhttpEth);

        const ethBalance = await customHttpProvider.getBalance(context.getters.wallet);
        context.commit('seteth_MaticBalance', ethBalance);

        const erc20_nsfw = new ethers.Contract(config.nsfwContractEth, abi, customHttpProvider);

        erc20_nsfw.balanceOf(context.getters.wallet).then((result) => {
            context.commit('seteth_NsfwBalance', result);
            // console.log("seteth_NsfwBalance: " + result);
        });

        axios.get('https://apiv5.paraswap.io/prices?srcToken='+config.nsfwContractEth+'&srcDecimals=18&destToken='+config.usdcContractEth+'&destDecimals=18&amount=1000000000000000000&side=SELL&network=1')
            .then(response => (
                    // console.log("USD : " + response.data.priceRoute.srcUSD)
                    context.commit('seteth_nsfwPerUsdc', response.data.priceRoute.srcUSD)
                )
            );
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}