import Vue from 'vue'
import Vuex from 'vuex'
import messageStore from './messageStore'
import walletStore from './walletStore'
import contractStorePolygon from './contractStorePolygon';
import contractStoreEtherum from "@/store/contractStoreEtherum";
import contractStoreBsc from "@/store/contractStoreBsc";
import stake4weeks from "@/store/stake4weeks";
import stake12weeks from "@/store/stake12weeks";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        messageStore,
        walletStore,
        poly: contractStorePolygon,
        eth: contractStoreEtherum,
        bsc: contractStoreBsc,
        stake4weeks,
        stake12weeks
    }
})