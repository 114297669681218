import { ethers } from "ethers";
import config from "@/config";
import {func} from "@/lib/walletFunctions"
import abiStaking from "@/resources/NSFWStaking.json";
// import BigNumber from "bignumber.js";
import nsfwabi from "@/resources/NSFW.json";
import nsfwstakingabi from "@/resources/NSFWStaking.json"



const state = () => ({
    s4_EntranceBalance: null,
    s4_MinimalUnlockDate: null,
    s4_StakedBalance: null,
    s4_TotalBalance: null,
    s4_UnlockAmount: null,
    s4_UnlockRequested: null,
    s4_UnlockedBalance: null,
    s4_UnlockedDate: null,
    s4_UserReward: null,
    s4_allowance: null,
})

const getters = {
    get_s4_EntranceBalance: (state) => {
        return state.s4_EntranceBalance;
    },
    get_s4_MinimalUnlockDate: (state) => {
        return state.s4_MinimalUnlockDate;
    },
    get_s4_StakedBalance: (state) => {
        return state.s4_StakedBalance;
    },
    get_s4_TotalBalance: (state) => {
        return state.s4_TotalBalance;
    },
    get_s4_UnlockAmount: (state) => {
        return state.s4_UnlockAmount;
    },
    get_s4_UnlockRequested: (state) => {
        return state.s4_UnlockRequested;
    },
    get_s4_UnlockedBalance: (state) => {
        return state.s4_UnlockedBalance;
    },
    get_s4_UnlockedDate: (state) => {
        return state.s4_UnlockedDate;
    },
    get_s4_UserReward: (state) => {
        return state.s4_UserReward;
    },
    get_s4_allowance: (state) => {
        return state.s4_allowance;
    }
}

const mutations = {
    set_s4_EntranceBalance(state, payload) {
        state.s4_EntranceBalance = payload;
    },
    set_s4_MinimalUnlockDate(state, payload) {
        state.s4_MinimalUnlockDate = payload;
    },
    set_s4_StakedBalance(state, payload) {
        state.s4_StakedBalance = payload;
    },
    set_s4_TotalBalance(state, payload) {
        state.s4_TotalBalance = payload;
    },
    set_s4_UnlockAmount(state, payload) {
        state.s4_UnlockAmount = payload;
    },
    set_s4_UnlockRequested(state, payload) {
        state.s4_UnlockRequested = payload;
    },
    set_s4_UnlockedBalance(state, payload) {
        state.s4_UnlockedBalance = payload;
    },
    set_s4_UnlockedDate(state, payload) {
        state.s4_UnlockedDate = payload;
    },
    set_s4_UserReward(state, payload) {
        state.s4_UserReward = payload;
    },
    set_s4_allowance(state, payload) {
        state.s4_allowance = payload;
    }
}

const actions = {
    async refreshWallet(context) {
        const customHttpProvider = new ethers.providers.JsonRpcProvider(config.qnhttpPoly);

        const nsfwContract = ethers.utils.getAddress(config.nsfwContractPoly);
        const nsfwContractSt4 = ethers.utils.getAddress(config.stakeContract4Poly);

        // Staking
        const erc20_nsfw = new ethers.Contract(nsfwContract, nsfwabi, customHttpProvider);
        const erc20_nsfw4s = new ethers.Contract(nsfwContractSt4, nsfwstakingabi, customHttpProvider);

        const set_s4_EntranceBalance = await erc20_nsfw4s.getEntranceBalance(context.getters.wallet);
        context.commit('set_s4_EntranceBalance', set_s4_EntranceBalance);

        const set_s4_MinimalUnlockDate = await erc20_nsfw4s.getMinimalUnlockDate(context.getters.wallet);
        context.commit('set_s4_MinimalUnlockDate', set_s4_MinimalUnlockDate);

        const set_s4_StakedBalance = await erc20_nsfw4s.getStakedBalance(context.getters.wallet);
        context.commit('set_s4_StakedBalance', set_s4_StakedBalance);

        const set_s4_TotalBalance = await erc20_nsfw4s.getTotalBalance(context.getters.wallet);
        context.commit('set_s4_TotalBalance', set_s4_TotalBalance);

        const set_s4_UnlockAmount = await erc20_nsfw4s.getUnlockAmount(context.getters.wallet);
        context.commit('set_s4_UnlockAmount', set_s4_UnlockAmount);

        const set_s4_UnlockRequested = await erc20_nsfw4s.getUnlockRequested(context.getters.wallet);
        context.commit('set_s4_UnlockRequested', set_s4_UnlockRequested);

        const set_s4_UnlockedBalance = await erc20_nsfw4s.getUnlockedBalance(context.getters.wallet);
        context.commit('set_s4_UnlockedBalance', set_s4_UnlockedBalance);

        try {
            const set_s4_UnlockedDate = await erc20_nsfw4s.getUnlockedDate(context.getters.wallet);
            context.commit('set_s4_UnlockedDate', set_s4_UnlockedDate);
        } catch (e) {
            // console.error(e); // console.log('No unlock date')
        }

        const set_s4_UserReward = await erc20_nsfw4s.getUserReward(context.getters.wallet);
        context.commit('set_s4_UserReward', set_s4_UserReward);

        // allowanc
        const set_s4_allowance = await erc20_nsfw.allowance(context.getters.wallet, nsfwContractSt4);
        context.commit('set_s4_allowance', set_s4_allowance);
    },
    async s4_claimReward(context) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            this.dispatch("switchChainPoly")
            return
        }

        const signer = context.getters.provider.getSigner();

        const erc20_nsfwSt4w = new ethers.Contract(ethers.utils.getAddress(config.stakeContract4Poly), abiStaking, signer);
        const receipt = await erc20_nsfwSt4w.claimReward()

        //console.log(receipt);

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
        //await context.dispatch('showProgress', 30)

        context.getters.provider.waitForTransaction(receipt.hash, config.transactionConfirmations, config.transactionTimeout)
            .then(await context.dispatch('sendMessage', 'Claiming validated'))
            .catch(
                context.dispatch('sendError', 'Claiming maybe failed! Check your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
    async s4_requestUnlock(context) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            this.dispatch("switchChainPoly")
            return
        }

        const signer = context.getters.provider.getSigner();

        const erc20_nsfwSt12w = new ethers.Contract(ethers.utils.getAddress(config.stakeContract4Poly), abiStaking, signer);
        const receipt = await erc20_nsfwSt12w.requestUnlock();

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
        //await context.dispatch('showProgress', 30)

        context.getters.provider.waitForTransaction(receipt.hash, config.transactionConfirmations, config.transactionTimeout)
            .then(await context.dispatch('sendMessage', 'RequestUnlock validated'))
            .catch(
                context.dispatch('sendError', 'RequestUnlock maybe failed! Check your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
    async s4_deposit(context) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            this.dispatch("switchChainPoly")
            return
        }

        const signer = context.getters.provider.getSigner();

        const erc20_nsfwSt4w = new ethers.Contract(ethers.utils.getAddress(config.stakeContract4Poly), abiStaking, signer);
        const unBal = func.getBalanceUnformated(context.getters.get_s4_allowance)
        const amount = ethers.utils.parseUnits(unBal, "ether")

        const gasEstimated = await erc20_nsfwSt4w.estimateGas.deposit(amount);

        const gasPrice = await context.getters.provider.getGasPrice()
        const gasLimit = Math.ceil((gasEstimated * config.gasPriceMultiplicator))

        const options = { gasLimit: gasLimit, gasPrice: gasPrice };

        const receipt = await erc20_nsfwSt4w.deposit(amount, options);


        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
        //await context.dispatch('showProgress', 30)

        context.getters.provider.waitForTransaction(receipt.hash, config.transactionConfirmations, config.transactionTimeout)
            .then(
                await context.dispatch('sendMessage', 'Deposit validated')
            )
            .then(
                await context.dispatch('set_s4_allowance', '0')
            )
            .catch(
                context.dispatch('sendError', 'Deposit maybe failed! Check your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
}

export default {
    state,
    getters,
    actions,
    mutations
}