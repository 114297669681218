<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        <nsfwBalancePolygon ></nsfwBalancePolygon>
      </b-col><b-col>
        <nsfwBalanceEtherum></nsfwBalanceEtherum>
    </b-col><b-col>
        <nsfwBalanceBsc></nsfwBalanceBsc>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import nsfwBalancePolygon from '../components/nsfwBalancePolygon.vue'
import nsfwBalanceEtherum from "@/components/nsfwBalanceEtherum.vue";
import nsfwBalanceBsc from "@/components/nsfwBalanceBsc.vue";

export default {
  name: "wallet",
  components: {
    nsfwBalancePolygon,
    nsfwBalanceEtherum,
    nsfwBalanceBsc
  },
  methods: {

  },
}
</script>

<style scoped>

</style>