<template>
  <div>
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title><img alt="Polygon" src="../assets/Polygon.png" width="24px" height="24px"> - Balance</b-card-title>
        <b-card-text>
          Matic: {{ this.$store.getters.poly_maticBalance }} <img alt="Matic" src="../assets/matic-logo_P22.png"> <br>
          N$FW: {{ this.$store.getters.poly_nsfwBalance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"><br>
          <small>~ $ {{ this.balanceInUSDC }}</small>
          <!--
          Staked 4 N$FW: {{ this.$store.getters.nsfwStacked4Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"> =~ $ {{ this.nsfwStacked4InUSDC }}<br>
          Staked 12 N$FW: {{ this.$store.getters.nsfwStacked12Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"> =~ $ {{ this.nsfwStacked12USDC }}
          -->
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  name: "nsfwBalancePolygon",
  data() {
    return {

    }
  },
  methods: {

  },
  computed: {
    balance() {
      return this.$store.getters.poly_nsfwBalance;
    },
    balanceInUSDC() {
      if(this.$store.getters.poly_nsfwBalance && this.$store.getters.poly_nsfwPerUsdc) {
        return this.$root.getPricePer(this.$store.getters.poly_nsfwBalanceRaw, this.$store.getters.poly_nsfwPerUsdc, 18)
      }
      return 0;
    }
  }
}
</script>

<style scoped>
.bd-example {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
</style>