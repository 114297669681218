import { ethers } from "ethers";
import config from "../config"
import BigNumber from "bignumber.js";
import axios from "axios";
import {func} from "@/lib/walletFunctions";

function getStringBalancee(balance) {
    const wantDecimals = 5
    const decimals = 18

    const BN = BigNumber.clone({ DECIMAL_PLACES: wantDecimals })

    let BNbalance

    if(typeof balance === "object") {
        BNbalance = new BN(balance.toString())
    } else {
        BNbalance = new BN(balance)
    }

    // const T = BNbalance.toFormat()
    // const L = BNbalance.div(10 ** decimals).toFormat()
    // console.log(L)
    return BNbalance.div(10 ** decimals).toFormat()
}

const state = () => ({
    poly_nsfwBalanceRaw: null,
    poly_maticBalanceRaw: null,
    poly_nsfwPerUsdc: null
})

// getters
const getters = {
    poly_nsfwBalanceRaw: (state) => {
        return state.poly_nsfwBalanceRaw;
    },
    poly_nsfwBalance: (state) => {
        if(state.poly_nsfwBalanceRaw) {
            return getStringBalancee(state.poly_nsfwBalanceRaw)
        }
        return 0;
    },
    poly_nsfwBalanceUnformated: (state) => {
        if(state.poly_nsfwBalanceRaw) {
            return func.getBalanceUnformated(state.poly_nsfwBalanceRaw)
        }
        return 0;
    },
    poly_maticBalanceRaw: (state) => {
        return state.poly_maticBalanceRaw;
    },
    poly_maticBalance: (state) => {
        if(state.poly_maticBalanceRaw) {
            return getStringBalancee(state.poly_maticBalanceRaw)
        }
        return 0;
    },
    poly_nsfwPerUsdc: (state) => {
        if(state.poly_nsfwPerUsdc) {
            return state.poly_nsfwPerUsdc
        }
        return 0;
    }
}
// mutations
const mutations = {
    setpoly_NsfwBalance(state, payload) {
        state.poly_nsfwBalanceRaw = payload;
    },
    setpoly_MaticBalance(state, payload) {
        state.poly_maticBalanceRaw = payload;
    },
    setpoly_nsfwPerUsdc(state, payload) {
        state.poly_nsfwPerUsdc = payload;
    }
}

// actions
const actions = {
    async refreshWallet(context) {
        //console.log('refreshWallet called (Polygon)')

        const customHttpProvider = new ethers.providers.JsonRpcProvider(config.qnhttpPoly);

        const ethBalance = await customHttpProvider.getBalance(context.getters.wallet);
        context.commit('setpoly_MaticBalance', ethBalance);

        const abi = require("../resources/NSFW.json");
        const erc20_nsfw = new ethers.Contract(config.nsfwContractPoly, abi, customHttpProvider);

        erc20_nsfw.balanceOf(context.getters.wallet).then((result) => {
            context.commit('setpoly_NsfwBalance', result);
            //console.log("setpoly_NsfwBalance: " + result);
        });

        axios.get('https://apiv5.paraswap.io/prices?srcToken='+config.nsfwContractPoly+'&srcDecimals=18&destToken='+config.usdcContractPoly+'&destDecimals=18&amount=1000000000000000000&side=SELL&network=137')
            .then(response => (
                    // console.log("USD : " + response.data.priceRoute.srcUSD)
                    context.commit('setpoly_nsfwPerUsdc', response.data.priceRoute.srcUSD)
                )
            );
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}