<template>
  <div>
    <!-- 12 weeks -->
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title>
          <b-row>
            <b-col>
              12 weeks staking N$FW: {{ s12_getStaked_balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png">
            </b-col>
            <b-col md="auto" v-if="this.$store.getters.get_s12_UnlockRequested">
              Unlocks after {{ withdrawDate12local }} days
            </b-col>
            <b-col md="auto" class="text-right">
              <b-button :disabled="this.$store.getters.get_s12_UnlockedBalance != null" @click="withdraw12w">Withdraw</b-button>
            </b-col>
          </b-row>
        </b-card-title>

        <b-input-group prepend="Claimable:" class="mt-3">
          <b-form-input readonly v-model="nsfwclaimable12Balance"></b-form-input>
          <b-input-group-append>
            <b-button @click="claim12w" :disabled="nsfwclaimable12Balance < 1" >Claim</b-button>
          </b-input-group-append>
        </b-input-group>


        <b-input-group prepend="Approve:" class="mt-3">
          <b-form-input readonly v-model="stake12sliderValue"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" @click="approve12w">Approve</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-input :disabled="this.$store.getters.poly_nsfwBalanceUnformated < minimumStakeValue()" id="range-st12" v-model="stake12sliderValue" type="range" min="100000" :max="this.$store.getters.poly_nsfwBalanceUnformated" step="5"></b-form-input>

        <b-row class="text-center">
          <b-col class="text-left">
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" @click="setSlidermin">min</b-button>
          </b-col>
          <b-col>
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" @click="setSliderhalf">50%</b-button>
          </b-col>
          <b-col class="text-right">
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" text-right @click="setSlidermax">max</b-button>
          </b-col>
        </b-row>


        <b-card v-if="stake12sliderValue > 0 " title="Return on 12% APY" class="mb-2">
          <b-input-group prepend="per Week:" class="mt-3">
            <b-form-input readonly v-model="stake12perWeekReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Month:" class="mt-3">
            <b-form-input readonly v-model="stake12perMonthReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Year:" class="mt-3">
            <b-form-input readonly v-model="stake12perYearReturn"></b-form-input>
          </b-input-group>
        </b-card>

        <b-input-group prepend="Transfer:" class="mt-3">
          <b-form-input readonly v-model="get_s12_allowanceUnformated"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="get_s12_allowanceUnformated < this.minimumStakeValue()" @click="deposit12w">Deposit</b-button>
            <b-button :disabled="get_s12_allowanceUnformated < this.minimumStakeValue()" @click="revoke12w">Revoke</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-row v-if="s12_getStaked_balanceUnformated > 0 && !this.$store.getters.get_s12_UnlockRequested">
          <b-col>
            <b-input-group prepend="Unlockable until :" class="mt-3">
              <b-form-input readonly v-model="unlockDate12local"></b-form-input>
              <b-form-input readonly value="days"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!unlockable" @click="unlock12w">Unlock</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>


      </b-card>
    </div>

  </div>
</template>

<script>

// import moment from 'moment'
import config from "../config"
import {ethers} from "ethers";
import {func} from "@/lib/walletFunctions"

export default {
  name: "nsfwStake",
  data() {
    return {
      insliderValue: 0,
      stake12sliderValue: 0,
      minStakeValue: config.minimumStakeValue,
      stake12visible: false
    }
  },
  methods: {
    setSlidermin() {
      if(this.poly_balanceUnformated > this.minStakeValue) {
        this.stake12sliderValue = this.minStakeValue;
      }
    },
    setSliderhalf(){
      this.stake12sliderValue = func.getBalanceUnformatedNoDecimals(this.$store.getters.poly_nsfwBalanceRaw / 2);
    },
    setSlidermax() {
      this.stake12sliderValue = func.getBalanceUnformatedNoDecimals(this.$store.getters.poly_nsfwBalanceRaw);
    },
    minimumStakeValue() {
      return config.minimumStakeValue
    },
    withdraw12w: function () {
      // console.log("Claim 12 clicked");
      this.$store.dispatch('s12_withdraw');
    },
    claim12w: function () {
      // console.log("Claim 12 clicked");
      this.$store.dispatch('s12_claimReward');
    },
    approve12w: function () {
      if(this.stake12sliderValue >= this.minStakeValue) {
        const transferAddress = ethers.utils.getAddress(config.stakeContract12Poly); // 12 week contract
        this.$store.dispatch('approve', {'toAddress': transferAddress, 'amount': this.stake12sliderValue});
      }
    },
    revoke12w: function () {
      if(this.$store.getters.get_s12_allowance) {
        const transferAddress = ethers.utils.getAddress(config.stakeContract12Poly); // 12 week contract
        this.$store.dispatch('revoke', {'toAddress': transferAddress, 'amount': func.getBalanceUnformated(this.$store.getters.get_s12_allowance)});
      }
    },//s12_requestUnlock
    deposit12w: function () {
      if(this.$store.getters.get_s12_allowance) {
        // const transferAddress = ethers.utils.getAddress(config.stakeContract12Poly); // 12 week contract
        this.$store.dispatch('s12_deposit');
      }
    },
    unlock12w: function () {
      if(this.$store.getters.get_s12_MinimalUnlockDate) {
        // const transferAddress = ethers.utils.getAddress(config.stakeContract12Poly); // 12 week contract
        this.$store.dispatch('s12_requestUnlock');
      }
    },
  },
  computed: {
    unlockable: {
      get: function() {
        if(this.s12_getStaked_balanceUnformated > 0 && !this.$store.getters.get_s12_UnlockRequested) {
          return true
        }
        return false
      }
    },
    transferable: {
      get: function() {
        if(this.$store.getters.get_s12_allowanceUnformated < this.minimumStakeValue()) {
          return false
        }
        return true
      }
    },
    poly_balance: {
      get: function () {
        return func.getStringBalanceMix(this.$store.getters.poly_nsfwBalanceRaw);
      }
    },
    poly_balanceUnformated: {
      get: function () {
        return func.getBalanceUnformated(this.$store.getters.poly_nsfwBalanceRaw);
      }
    },
    s12_getStaked_balance: {
      get: function () {
        return func.getStringBalanceMix(this.$store.getters.get_s12_TotalBalance);
      }
    },
    s12_getStaked_balanceUnformated: {
      get: function () {
        return func.getBalanceUnformated(this.$store.getters.get_s12_TotalBalance);
      }
    },
    get_s12_allowanceUnformated: {
      get: function () {
        if (this.$store.getters.get_s12_allowance) {
          return func.getBalanceUnformated(this.$store.getters.get_s12_allowance)
        }
        return 0
      }
    },
    getComputedBalance: {
      get: function () {
        if (this.$store.getters.get_s12_TotalBalance) {
          return func.getStringBalanceMix(this.$store.getters.get_s12_TotalBalance)
        }
        return 0
      }
    },
    nsfwclaimable12Balance: {
      get: function () {
        if (this.$store.getters.get_s12_UserReward) {
          return func.getBalanceUnformated(this.$store.getters.get_s12_UserReward)
        }
        return '';
      }
    },
    stake12perWeekReturn() {
      if(this.stake12sliderValue > 0) {
        return ((this.stake12sliderValue * 0.1) / 53).toLocaleString('en-US', {minimumFractionDigits: 2});
      }
      return '';
    },
    stake12perMonthReturn() {
      if(this.stake12sliderValue > 0) {
        return ((this.stake12sliderValue * 0.1) / 53) * 4
      }
      return '';
    },
    stake12perYearReturn() {
      if(this.stake12sliderValue > 0) {
        return this.stake12sliderValue * 0.1
      }
      return '';
    },
    unlockDate12local: {
      get: function() {
        const days = func.getDaysFromBigNumber(this.$store.getters.get_s12_MinimalUnlockDate)
        const date = func.getDateFromBigNumber(this.$store.getters.get_s12_MinimalUnlockDate)
        return date + ' in ' + days
      }
    },
    withdrawDate12local: {
      get: function() {
        const days = func.getDaysFromBigNumberwithdraw(this.$store.getters.get_s12_MinimalUnlockDate)
        const date = func.getDateFromBigNumberwithdraw(this.$store.getters.get_s12_MinimalUnlockDate)
        return date + ' in ' + days
      }
    },
    restakeDate12local: {
      get: function() {
        return this.unlockDate12local + 7
      }
    },
    setAmount() {
      if(this.insliderValue > 0 && this.poly_balanceUnformated && this.poly_balanceUnformated > 0) {

        let tVal = (this.poly_balanceUnformated / 100 * this.insliderValue).toString();
        if(tVal.length >= tVal.indexOf(".")+6) {
          tVal = tVal.substring(0, tVal.indexOf(".")+6);
        }
        return tVal;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>
.bd-example {
  padding: 1.5rem;
  border: solid #dee2e6;
  border-width: 2px;
  border-radius: .5rem;
}

</style>