export default {
    nsfwContractPoly: '0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19',
    usdcContractPoly: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    nsfwContractEth: '0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19',
    usdcContractEth: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    nsfwContractBsc: '0xaA076B62EFC6f357882E07665157A271aB46A063',
    usdcContractBsc: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',

    stakeContract4Poly: '0xAad3B603623032d9941AF26599a256830fba2254',
    stakeContract12Poly: '0x84ED758f73C994D61B4a119c06700889C8463F8B',

    qnhttpPoly: 'https://crimson-muddy-water.matic.quiknode.pro/c86a863eefb58c23712da87fbb11cf0bae9e81a5/',
    qnwssPoly: 'wss://crimson-muddy-water.matic.quiknode.pro/c86a863eefb58c23712da87fbb11cf0bae9e81a5/',
    qnhttpEth: 'https://maximum-young-energy.quiknode.pro/02fd7aff25586affea4e286a957eb249eab06619/',
    qnwssEth: 'wss://maximum-young-energy.quiknode.pro/02fd7aff25586affea4e286a957eb249eab06619/',
    qnhttpBsc: 'https://damp-withered-pond.bsc.quiknode.pro/ffba3e03bf1a9b34a4f020456c823d53b42b472a/',
    qnwssBsc: 'wss://damp-withered-pond.bsc.quiknode.pro/ffba3e03bf1a9b34a4f020456c823d53b42b472a/',

    minimumStakeValue: 100000,
    gasPriceMultiplicator: 1.8,
    transactionTimeout:60000,
    transactionConfirmations: 10
}