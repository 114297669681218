<template>
  <div>
    <!-- 4 weeks -->
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title>
          <b-row>
            <b-col>
              4 weeks staking N$FW: {{ s4_getStaked_balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png">
            </b-col>
            <b-col md="auto" v-if="this.$store.getters.get_s4_UnlockRequested">
              Unlocks after {{ withdrawDate4local }} days
            </b-col>
            <b-col md="auto" class="text-right">
              <b-button :disabled="this.$store.getters.get_s4_UnlockedBalance != null" @click="withdraw4w">Withdraw</b-button>
            </b-col>
          </b-row>
        </b-card-title>

        <b-input-group prepend="Claimable:" class="mt-3">
          <b-form-input readonly v-model="nsfwclaimable4Balance"></b-form-input>
          <b-input-group-append>
            <b-button @click="claim4w" :disabled="nsfwclaimable4Balance < 1" >Claim</b-button>
          </b-input-group-append>
        </b-input-group>


        <b-input-group prepend="Approve:" class="mt-3">
          <b-form-input readonly v-model="stake4sliderValue"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" @click="approve4w">Approve</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-input :disabled="this.$store.getters.poly_nsfwBalanceUnformated < minimumStakeValue()" id="range-st4" v-model="stake4sliderValue" type="range" min="100000" :max="this.$store.getters.poly_nsfwBalanceUnformated" step="5"></b-form-input>

        <b-row class="text-center">
          <b-col class="text-left">
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" @click="setSlidermin">min</b-button>
          </b-col>
          <b-col>
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" @click="setSliderhalf">50%</b-button>
          </b-col>
          <b-col class="text-right">
            <b-button :disabled="this.$store.getters.poly_nsfwBalance < minimumStakeValue()" pill variant="info" size="sm" text-right @click="setSlidermax">max</b-button>
          </b-col>
        </b-row>


        <b-card v-if="stake4sliderValue > 0 " title="Return on 4% APY" class="mb-2">
          <b-input-group prepend="per Week:" class="mt-3">
            <b-form-input readonly v-model="stake4perWeekReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Month:" class="mt-3">
            <b-form-input readonly v-model="stake4perMonthReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Year:" class="mt-3">
            <b-form-input readonly v-model="stake4perYearReturn"></b-form-input>
          </b-input-group>
        </b-card>

        <b-input-group prepend="Transfer:" class="mt-3">
          <b-form-input readonly v-model="get_s4_allowanceUnformated"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="get_s4_allowanceUnformated < this.minimumStakeValue()" @click="deposit4w">Deposit</b-button>
            <b-button :disabled="get_s4_allowanceUnformated < this.minimumStakeValue()" @click="revoke4w">Revoke</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-row v-if="s4_getStaked_balanceUnformated > 0 && !this.$store.getters.get_s4_UnlockRequested">
          <b-col>
            <b-input-group prepend="Unlockable until :" class="mt-3">
              <b-form-input readonly v-model="unlockDate4local"></b-form-input>
              <b-form-input readonly value="days"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!unlockable" @click="unlock4w">Unlock</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>


      </b-card>
    </div>

  </div>
</template>

<script>

// import moment from 'moment'
import config from "../config"
import {ethers} from "ethers";
import {func} from "@/lib/walletFunctions"

export default {
  name: "nsfwStake",
  data() {
    return {
      insliderValue: 0,
      stake4sliderValue: 0,
      minStakeValue: config.minimumStakeValue,
      stake4visible: false
    }
  },
  methods: {
    setSlidermin() {
      if(this.poly_balanceUnformated > this.minStakeValue) {
        this.stake4sliderValue = this.minStakeValue;
      }
    },
    setSliderhalf(){
      this.stake4sliderValue = func.getBalanceUnformatedNoDecimals(this.$store.getters.poly_nsfwBalanceRaw / 2);
    },
    setSlidermax() {
      this.stake4sliderValue = func.getBalanceUnformatedNoDecimals(this.$store.getters.poly_nsfwBalanceRaw);
    },
    minimumStakeValue() {
      return config.minimumStakeValue
    },
    withdraw4w: function () {
      console.log("Claim 4 clicked");
      this.$store.dispatch('s4_withdraw');
    },
    claim4w: function () {
      console.log("Claim 4 clicked");
      this.$store.dispatch('s4_claimReward');
    },
    approve4w: function () {
      if(this.stake4sliderValue >= this.minStakeValue) {
        const transferAddress = ethers.utils.getAddress(config.stakeContract4Poly); // 4 week contract
        this.$store.dispatch('approve', {'toAddress': transferAddress, 'amount': this.stake4sliderValue});
      }
    },
    revoke4w: function () {
      if(this.$store.getters.get_s4_allowance) {
        const transferAddress = ethers.utils.getAddress(config.stakeContract4Poly); // 4 week contract
        this.$store.dispatch('revoke', {'toAddress': transferAddress, 'amount': func.getBalanceUnformated(this.$store.getters.get_s4_allowance)});
      }
    },//s4_requestUnlock
    deposit4w: function () {
      if(this.$store.getters.get_s4_allowance) {
        // const transferAddress = ethers.utils.getAddress(config.stakeContract4Poly); // 4 week contract
        this.$store.dispatch('s4_deposit');
      }
    },
    unlock4w: function () {
      if(this.$store.getters.get_s4_MinimalUnlockDate) {
        // const transferAddress = ethers.utils.getAddress(config.stakeContract4Poly); // 4 week contract
        this.$store.dispatch('s4_requestUnlock');
      }
    },
  },
  computed: {
    unlockable: {
      get: function() {
        if(this.s4_getStaked_balanceUnformated > 0 && !this.$store.getters.get_s4_UnlockRequested) {
          return true
        }
        return false
      }
    },
    transferable: {
      get: function() {
        if(this.$store.getters.get_s4_allowanceUnformated < this.minimumStakeValue()) {
          return false
        }
        return true
      }
    },
    poly_balance: {
      get: function () {
        return func.getStringBalanceMix(this.$store.getters.poly_nsfwBalanceRaw);
      }
    },
    poly_balanceUnformated: {
      get: function () {
        return func.getBalanceUnformated(this.$store.getters.poly_nsfwBalanceRaw);
      }
    },
    s4_getStaked_balance: {
      get: function () {
        return func.getStringBalanceMix(this.$store.getters.get_s4_TotalBalance);
      }
    },
    s4_getStaked_balanceUnformated: {
      get: function () {
        return func.getBalanceUnformated(this.$store.getters.get_s4_TotalBalance);
      }
    },
    get_s4_allowanceUnformated: {
      get: function () {
        if (this.$store.getters.get_s4_allowance) {
          return func.getBalanceUnformated(this.$store.getters.get_s4_allowance)
        }
        return 0
      }
    },
    getComputedBalance: {
      get: function () {
        if (this.$store.getters.get_s4_TotalBalance) {
          return func.getStringBalanceMix(this.$store.getters.get_s4_TotalBalance)
        }
        return 0
      }
    },
    nsfwclaimable4Balance: {
      get: function () {
        if (this.$store.getters.get_s4_UserReward) {
          return func.getBalanceUnformated(this.$store.getters.get_s4_UserReward)
        }
        return '';
      }
    },
    stake4perWeekReturn() {
      if(this.stake4sliderValue > 0) {
        return ((this.stake4sliderValue * 0.04) / 53).toLocaleString('en-US', {minimumFractionDigits: 2});
      }
      return '';
    },
    stake4perMonthReturn() {
      if(this.stake4sliderValue > 0) {
        return ((this.stake4sliderValue * 0.04) / 53) * 4
      }
      return '';
    },
    stake4perYearReturn() {
      if(this.stake4sliderValue > 0) {
        return this.stake4sliderValue * 0.04
      }
      return '';
    },
    unlockDate4local: {
      get: function() {
        const days = func.getDaysFromBigNumber(this.$store.getters.get_s4_MinimalUnlockDate)
        const date = func.getDateFromBigNumber(this.$store.getters.get_s4_MinimalUnlockDate)
        return date + ' in ' + days
      }
    },
    withdrawDate4local: {
      get: function() {
        const days = func.getDaysFromBigNumberwithdraw(this.$store.getters.get_s4_MinimalUnlockDate)
        const date = func.getDateFromBigNumberwithdraw(this.$store.getters.get_s4_MinimalUnlockDate)
        return date + ' in ' + days
      }
    },
    restakeDate4local: {
      get: function() {
        return this.unlockDate4local + 7
      }
    },
    setAmount() {
      if(this.insliderValue > 0 && this.poly_balanceUnformated && this.poly_balanceUnformated > 0) {

        let tVal = (this.poly_balanceUnformated / 100 * this.insliderValue).toString();
        if(tVal.length >= tVal.indexOf(".")+6) {
          tVal = tVal.substring(0, tVal.indexOf(".")+6);
        }
        return tVal;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>
.bd-example {
  padding: 1.5rem;
  border: solid #dee2e6;
  border-width: 2px;
  border-radius: .5rem;
}

</style>