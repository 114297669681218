import { ethers } from "ethers";
import config from "../config"
import abi from "@/resources/NSFW.json";

const state = () => ({
    isconnected: false,
    wallet: null,
    web3: null,
    provider: null
})

// getters
const getters = {
    isconnected: (state) => {
        return state.isconnected;
    },
    wallet: (state) => {
        return state.wallet;
    },
    web3: (state) => {
        return state.web3;
    },
    provider: (state) => {
        return state.provider;
    }
}
// mutations
const mutations = {
    setConnected(state, payload) {
        state.isconnected = payload;
    },
    setWallet(state, payload) {
        state.wallet = payload;
    },
    setProvider(state, payload) {
        state.provider = payload;
    },
    setWeb3(state, payload) {
        state.web3 = payload;
    }
}

// actions
const actions = {
    async setWeb3(context, instance) {

        // console.log('setWeb3');

        console.log(config.nsfwContract);

        context.commit('setWeb3', instance);
        // const provider = new ethers.providers.Web3Provider(instance);
        const provider = new ethers.providers.Web3Provider(instance, 'any');
        const walletAddress = await provider.getSigner().getAddress()

        context.commit('setWallet', walletAddress);
        context.commit('setProvider', provider);
        context.commit('setConnected', true);
        localStorage.setItem('isWalletConnected', 'true')
        await context.dispatch('refreshWallet', context);
    },
    async approve(context, payload) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            this.dispatch("switchChainPoly")
            return
        }

        const signer = context.getters.provider.getSigner();

        const nsfwContract = ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19');

        const erc20_nsfw = new ethers.Contract(nsfwContract, abi, signer);
        const amount = payload.amount.toString()

        const gasEstimated = await erc20_nsfw.estimateGas.approve(
            ethers.utils.getAddress(payload.toAddress),
            ethers.utils.parseUnits(amount)
        );

        const gasPrice = await context.getters.provider.getGasPrice()
        const gasLimit = Math.ceil((gasEstimated * config.gasPriceMultiplicator))

        const options = { gasLimit: gasLimit, gasPrice: gasPrice };
        // console.log(options)

        const receipt = await erc20_nsfw.approve(
            ethers.utils.getAddress(payload.toAddress),
            ethers.utils.parseUnits(amount), options
        );

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
        // await context.dispatch('showProgress', 30)

        context.getters.provider.waitForTransaction(receipt.hash,  config.transactionConfirmations, config.transactionTimeout)
            .then(
                await context.dispatch('sendMessage', 'Approve validated')
            )
            .then(
                await context.dispatch('sendMessage', 'Please wait until they appear in transfer')
            )
            .catch(
                context.dispatch('sendError', 'Approve maybe failed! Check your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
    async switchChainPoly(context) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: "0x89",
                    rpcUrls: ["https://polygon-rpc.com/"],
                    chainName: "Matic Mainnet",
                    nativeCurrency: {
                        name: "MATIC",
                        symbol: "MATIC",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://polygonscan.com/"]
                }]
            });
        }
    },
    async revoke(context, payload) {

        const { chainId } = await context.getters.provider.getNetwork()

        if(chainId != 137) {
            this.dispatch("switchChainPoly")
            return
        }

        const signer = context.getters.provider.getSigner();

        const nsfwContract = ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19');

        const erc20_nsfw = new ethers.Contract(nsfwContract, abi, signer);

        const gasEstimated = await erc20_nsfw.estimateGas.approve(
            ethers.utils.getAddress(payload.toAddress),
            ethers.utils.parseUnits('0')
        );

        const gasPrice = await context.getters.provider.getGasPrice()
        const gasLimit = Math.ceil((gasEstimated * config.gasPriceMultiplicator))

        const options = { gasLimit: gasLimit, gasPrice: gasPrice };

        const receipt = await erc20_nsfw.approve(
            ethers.utils.getAddress(payload.toAddress),
            ethers.utils.parseUnits('0'), options
        );

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
        await context.dispatch('showProgress', 30)

        context.getters.provider.waitForTransaction(receipt.hash,  config.transactionConfirmations, config.transactionTimeout)
            .then(await context.dispatch('sendMessage', 'Approve validated'))
            .catch(
                context.dispatch('sendError', 'Approve maybe failed! Check your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
}

export default {
    state,
    getters,
    actions,
    mutations
}