<template>
  <div>
    <b-modal id="modal-1" title="What’s staking?" style="color:black;" ok-only>
      <p class="my-4">Staking is locking up your tokens for a period of either 4 or 12 weeks in order to earn passive income.
        Staking for 4 weeks will give you an APY of 4%.
        Staking for 12 weeks will give you an APY of 10%.

        The NSFW rewards will be unlocked to participants weekly
      </p>
      <h3>Staking is as easy as 1, 2, 3</h3>
      <p class="my-4">
        <b-badge>1</b-badge>Connect to the site (On Polygon Network)<br>
        <b-badge>2</b-badge>Choose 4 or 12 Weeks and Enter the amount you want to stake (1 million minimum)<br>
        <b-badge>3</b-badge>Approve the deposit<br>
        <b-badge>4</b-badge>Stake the deposit<br>
      </p>
      <h3>Things to remember:</h3>
      <p class="my-4">
        You can stake at any point, but the rewards only begin generating in the following week. So if you start staking on Wednesday of week 1, the 4 week period starts in the following week (week 2) and lasts for either 4 or 12 weeks from then, depending on the pool chosen.

        You can add to your staking amount, but the staking period resets based on your last deposit. So if you stake for 4 weeks in week 1, but top up in week 2, your 4 weeks period resets and lasts until the end of week 6.

        If at the end of the staking period, a user doesn’t manually unlock their stake, they will be auto-re-staked for the same period.

        To unlock the stake at the end of the period, the request must be made using the “Unlock” button any time before the final week. Eg a 4 weeks staking period ends in week 5 - if you don’t want to renew, click the “Unlock” button in week 1-4.

        Unlock date is written in DD/MM/YYYY format.

        Once a stake is unlocked, rewards are no longer earned and NSFW can be withdrawn by using the withdraw button.
      </p>
    </b-modal>

    <b-container>
      <b-row class="justify-content-md-center">
        <b-col>
          <div class="bd-example">
            <label for="balance-1">Stakeable N$FW: {{ this.$store.getters.poly_nsfwBalance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"></label>
            <b-button v-b-modal.modal-1 pill variant="info" size="sm">INFO</b-button>
            <p v-if="this.$store.getters.poly_nsfwBalance < minimumStakeValue()">You need at least {{ minimumStakeValueFormated() }} N$FW in your wallet at Polygon to be able to use approve, transfer or stake.</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
        <b-col>
          <nsfwStake4Weeks></nsfwStake4Weeks>
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
          <b-col>
            <nsfwStake12Weeks></nsfwStake12Weeks>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import nsfwStake4Weeks from '../components/nsfwStake4Weeks.vue'
import nsfwStake12Weeks from '../components/nsfwStake12Weeks.vue'
import config from "@/config";

export default {
  name: "wallet",
  components: {
    nsfwStake4Weeks,
    nsfwStake12Weeks
  },
  methods: {
    minimumStakeValue() {
      return config.minimumStakeValue
    },
    minimumStakeValueFormated() {
      const resultd = (config.minimumStakeValue).toLocaleString('en-US');
      return resultd
    }
  }
}
</script>

<style scoped>
.bd-example {
  padding: 1.5rem;
  border: solid #dee2e6;
  border-width: 2px;
  border-radius: .5rem;
}
</style>